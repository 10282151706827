<template>
  <div>
    <div class="col-12 ">
      <section>
<!--        <div class="row">-->
<!--          <div class="col-4">-->
<!--            <div class="filter">-->
<!--              &lt;!&ndash; <label>{{ $t('portalStats.selectProfile') }}</label> &ndash;&gt;-->
<!--              <div class="input-group input-group filters__location">-->
<!--                <Multi-select-component-->
<!--                  v-model="selectedProfileInMultiselect"-->
<!--                  class="w-100"-->
<!--                  option-id-name="id"-->
<!--                  track-by="name"-->
<!--                  :placeholder="$t('portalStats.selectProfile')"-->
<!--                  :async-function="requestProfiles.bind(this)"-->
<!--                  @select="getUsersBirthdays"-->
<!--                />-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="col-4">-->
<!--            <div class="filter">-->
<!--              <SelectComponentV2-->
<!--                v-model="filters.location"-->
<!--                track-by="name"-->
<!--                option-id-name="id"-->
<!--                :async-function="requestLocations.bind(this)"-->
<!--                :placeholder="$t('portalStats.selectLocation')"-->
<!--                @select="changeLocation"-->
<!--              >-->
<!--                <template #dropdown-start>-->
<!--                      <span class="select__option select__option_clickable"-->
<!--                            @click="toggleWithChilds">-->
<!--                        <Checkbox-component-->
<!--                          class="w-100"-->
<!--                          name="with_childs"-->
<!--                          :label="$t('portalStats.selectLocationWithChild')"-->
<!--                          id="with_childs"-->
<!--                          :checked="filters.with_childs === true"-->
<!--                          @click="toggleWithChilds"-->
<!--                        />-->
<!--                      </span>-->
<!--                </template>-->
<!--              </SelectComponentV2>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
              <div class="row">
        <div class="col-lg-3 mb-h input-group-for-location-select-in-PortalBirthdays--filters" v-if="areLocationsVisible">

                                                                                                           <span
                                                                                                             class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location.data}"></i>
                </span>
          <SelectComponentV3ForUseInLocationSelectors

            v-if="showFilters"
            no-wrap-selected-option
            class="select-location-in-PortalBirthdays--filters"
            enable-max-option-width
            small-size
            :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
            :show-delete-button="!isSelectedDefaultLocationForRequests"
            v-model="filters.location"
            :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
            track-by="name"
            option-id-name="id"
            :no-options-found="$t('general.noResultForSearch')"
            :async-function="requestLocations.bind(this)"
            :placeholder="$t('portalStats.selectLocation')"
            @select="changeLocation"
          >
            <template #dropdown-start>
              <div class="ml-h mt-1">
                <Switch-component
                  v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                  @input="toggleWithChilds"
                  :label="$t('general.withChild')"
                  class=""
                  v-model="filters.with_childs"
                />
              </div>
            </template>
          </SelectComponentV3ForUseInLocationSelectors>


        </div>
        <div class="col-lg-3 mb-h">
          <div class="input-group input-group-sm w-100">
                        <span class="input-group-addon">
              <i class="fa fa-file-o" />
            </span>
                            <Multi-select-component
                              v-if="showFilters"
                    v-model="selectedProfileInMultiselect"
                    class="multiselect-in-PortalBirthdays--filters w-100"
                    option-id-name="id"
                    track-by="name"
                    :placeholder="$t('portalStats.selectProfile')"
                    :async-function="requestProfiles.bind(this)"
                    @select="getUsersBirthdays"
                  />
            </div>
        </div>
      </div>

      <div class="row">
              <div class="col-lg-4">
        <div class="filters__multiple">
          <div class="input-group input-group-sm filters__wlan flex-wrap mr-1">
            <WButton secondary outline sm
              v-if="isFiltersActive"
              @click.stop.prevent="resetAllFilters"
            >
              <i class="fa fa-close"></i>
              {{ $t('general.resetAllFilters') }}
            </WButton>
          </div>
        </div>
      </div>
      </div>

      </section>
    </div>

    <div class="col-12 animated fadeIn">
      <div class="row">
        <div class="col-lg-8 col-md-12 order-lg-1 order-md-2">
          <div class="card">
            <!--            <div class="loader loader-backdrop loader-backdrop-portaluseraccounts" v-if="isPortalBirthdaysLoading"></div>-->
            <div v-if="isPortalBirthdaysLoading"><Loader-spinner /></div>
            <div class="card-header d-flex align-items-center justify-content-between">
              <div>
                <i class="fa fa-users mr-0"></i>
                {{ $t('portalBirthdays.users') }}
              </div>
              <div class="d-flex align-items-center">
                <small class="badge badge-dark ml-2">
                  {{ $t('portalBirthdays.selectedDate') }}: {{ showSelectedDate }}
                </small>
                <download-extract-v2
                  newXlsAutoColumnsWidth
                  v-if="!isPortalBirthdaysLoading"
                  class="ml-h"
                  :data="formatPortalBirthdays()"
                  :data-for-excel="formatPortalBirthdaysToExcel()"
                  :excel-file-name="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalBirthdays')}-${showSelectedDate}`)"
                  :filename="getFileNameForDownloadFiles(`${$t('downloadFilesNames.portalBirthdays')}-${showSelectedDate}`)"
                  :downloads-formats-for-show="{
                    oldCsv: false,
                    newCsv: true,
                    oldXls: false,
                    newXls: true,
                    pdf: true
                  }"
                />
              </div>
            </div>
            <div class="card-block card-block__table table-responsive">
              <!-- <h5 class="mb-1"></h5> -->
              <!-- <div class="row">
            <div class="col-lg-12"> -->
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th></th>
                    <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.username') }}</th>
                    <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.profile') }}</th>
                    <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.name') }}</th>
                    <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.birthday') }}</th>
                    <th scope="col" class="text-nowrap align-middle">{{ $t('userAccount.socialLinks') }}</th>
                    <!-- <th class="col-2 text-nowrap">{{ $t('userAccount.balance') }}</th> -->
                    <!-- <th>{{ $t('general.mac') }}</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(account, index) in userAccountsListByBirthday" :key="account.id">
                    <th scope="row" class="align-middle">{{ index + 1 }}</th>
                    <td class="align-middle">
                      <span v-if="account.identity">
                        {{ account.identity }}
                      </span>
                      <EmptyCellValue v-else />
                    </td>
                    <td class="align-middle">
                      <span v-if="account.profile">
                        {{ getProfileNameByID(account.profile) }}
                      </span>
                      <EmptyCellValue v-else />
                    </td>
                    <td class="align-middle">
                      <span v-if="account.name || account.surname">
                        {{ getFullName(account.name, account.surname) }}
                      </span>
                      <EmptyCellValue v-else />
                    </td>
                    <td class="align-middle">
                      <div v-if="account.date_of_birth_struct">
                        <span>
                          {{ showUserBirthday(account.date_of_birth_struct) }}
                        </span>
                        <span
                          class="badge"
                          :class="{
                            'badge-info': showUserAge(account.date_of_birth_struct) % 5 !== 0,
                            'badge-success': showUserAge(account.date_of_birth_struct) % 5 === 0
                          }"
                        >
                          {{ showUserAge(account.date_of_birth_struct) }}
                        </span>
                      </div>
                      <EmptyCellValue v-else />
                    </td>
                    <td class="align-middle">
                      <div v-if="account.social_network && Object.keys(account.social_network).length > 0">
                        <span v-for="(social, index) in account.social_network" :key="index">
                          <a
                            :href="showUserSocialLink(index, social)"
                            target="_blank"
                            rel="noopener noreferrer"
                            class="btn btn-primary m-q py-q px-1"
                          >
                            <i
                              class="fa fa-2x"
                              :class="{ 'fa-vk': index === 'vk', 'fa-facebook-square': index === 'fb' || index === 'facebook' }"
                            ></i>
                          </a>
                        </span>
                      </div>
                      <EmptyCellValue v-else />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12 order-lg-2 order-md-1">
          <div class="card sticky-top">
            <!--            <div v-if="isPortalAdsLoading" class="loader loader-backdrop loader-backdrop-portalads" />-->
            <div v-if="isPortalAdsLoading"><Loader-spinner /></div>
            <div class="card-header">
              <i class="fa fa-calendar-o" />
              {{ $t('portalBirthdays.birthdays') }}
            </div>
            <div class="card-block">
              <!-- <div>
                <h5>{{ $t('portalBirthdays.selectedDate') }} : {{ showSelectedDate }}</h5>
              </div> -->
              <calendar
                v-if="showFilters"
                class=""
                :value="selectedDateOnCalendar"
                :pane="1"
                :has-input="false"
                :on-day-click="onDayClick"
                :first-day-of-week="1"
                :change-pane="changePaneCalendar"
              >
                <div v-for="(evt, index) in events" :slot="evt.id" :key="index" class="day-icon">
                  <small>{{ evt.content }}</small>
                </div>
              </calendar>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
// import Calendar from 'vue2-datepicker';
import Calendar from 'vue2-slot-calendar';

import MultiSelectComponent from '../../components/Universal/select/multi-select-component.vue';
import EmptyCellValue from '../../components/empty-cell-value.vue';
import helpers from '../../helpers';
import portalProfilesService from '../../services/portalProfilesService';
import portalBirthdayService from '../../services/portalBirthdaysService';
import portalUserAccountsService from '../../services/portalUserAccountsService';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from "../../services/locationService";
import CheckboxComponent from '../../components/Universal/Checkbox-component.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';

export default {
  name: 'PortalBirthdays',
  components: {
    EmptyCellValue,
    Calendar,
    MultiSelectComponent,
    SelectComponentV2,
    CheckboxComponent,
    SwitchComponent,
    SelectComponentV3ForUseInLocationSelectors
  },
  data() {
    return {
      showFilters: true,
      yearOnCalendar: null,
      selectedProfileInMultiselect: [],
      events: [],
      selectedDateOnCalendar: new Date(),
      selectedProfile: { name: this.$t('portalStats.allProfiles'), id: '' },
      isPortalAdsLoading: false,
      filters: {
        profiles: false,
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        location: {},
        with_childs: false,

      }
    };
  },
  watch: {
    // account: {
    //   deep: true,
    //   handler() {
    //     watcher.tick();
    //   }
    // },
    filters: {
      deep: true,
      handler() {
        this.getUsersBirthdays();
      }
    }
  },
  methods: {
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    redrawFilters(){
      this.showFilters = false;
      this.$nextTick(()=>{
        this.showFilters = true;
      })
    },
    // resetAllFilters() {
    //   this.filters = {
    //     profiles: false,
    //     day: new Date().getDate(),
    //     month: new Date().getMonth() + 1,
    //     year: new Date().getFullYear(),
    //     location: {},
    //     with_childs: true,
    //   }
    //   this.selectedProfileInMultiselect = [];
    //   this.events = [];
    //   this.selectedDateOnCalendar = new Date();
    //   this.selectedProfile = {name: this.$t('portalStats.allProfiles'), id: ''};
    //   this.redrawFilters()
    // },
    resetAllFilters() {
      this.filters = {
        profiles: false,
        day: new Date().getDate(),
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
        // location: {},
        // with_childs: true,
      }
      this.selectedProfileInMultiselect = [];
      this.events = [];
      this.selectedDateOnCalendar = new Date();
      this.selectedProfile = {name: this.$t('portalStats.allProfiles'), id: ''};

      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this)
      }

      this.redrawFilters()
    },
    toggleWithChilds() {
      // this.filters.with_childs = !this.filters.with_childs;

    },
    changeLocation(selectedLocation) {
      if (!selectedLocation) {
        this.filters.with_childs = true;
        this.filters.location = {}
      }
    },
    getFileNameForDownloadFiles(pageName) {
      return helpers.getFileNameForDownloadFiles(pageName)
    },
    async requestProfiles(searchQuery) {
      // return portalProfilesService.requestProfiles(searchQuery);
      return portalProfilesService.requestProfilesFilteredByLocationAndWithChilds(this, searchQuery);
    },
    formatPortalBirthdays() {
      const result = [];
      for (const account of this.userAccountsListByBirthday) {
        const accountObj = {};
        accountObj[this.$t('userAccount.username')] = '';
        if (account.identity) {
          accountObj[this.$t('userAccount.username')] = account.identity;
        }
        accountObj[this.$t('userAccount.profile')] = '';
        if (account.profile) {
          accountObj[this.$t('userAccount.profile')] = this.getProfileNameByID(account.profile);
        }
        accountObj[this.$t('userAccount.name')] = '';
        if (account.name || account.surname) {
          accountObj[this.$t('userAccount.name')] = this.getFullName(account.name, account.surname);
        }
        accountObj[this.$t('userAccount.birthday')] = '';
        if (account.date_of_birth_struct) {
          accountObj[this.$t('userAccount.birthday')] = this.showUserBirthdayForDownalods(account.date_of_birth_struct);
        }
        accountObj[this.$t('userAccount.socialLinks')] = '';
        if (account.social_network && Object.keys(account.social_network).length > 0) {
          let linksString = '';
          for (const socialNetworkType in account.social_network) {
            linksString = `${
              linksString + this.showUserSocialLink(socialNetworkType, account.social_network[socialNetworkType])
            }`;
          }
          accountObj[this.$t('userAccount.socialLinks')] = linksString.trim();
        }
        result.push(accountObj);
      }
      return result;
    },
    formatPortalBirthdaysToExcel() {
      const result = {};
      if (this.userAccountsListByBirthday.length === 0) {
        return result;
      }
      result[this.$t('sidebar.birthdays')] = [];
      const accountsToExcel = result[this.$t('sidebar.birthdays')];
      accountsToExcel.push([
        this.$t('userAccount.username'),
        this.$t('userAccount.profile'),
        this.$t('userAccount.name'),
        this.$t('userAccount.birthday'),
        this.$t('userAccount.socialLinks')
      ]);
      for (const account of this.userAccountsListByBirthday) {
        let username = '';
        let profile = '';
        let name = '';
        let birthday = '';
        let socialLinks = '';
        if (account.identity) {
          username = account.identity;
        }

        if (account.profile) {
          profile = this.getProfileNameByID(account.profile);
        }

        if (account.name || account.surname) {
          name = this.getFullName(account.name, account.surname);
        }

        if (account.date_of_birth_struct) {
          birthday = this.showUserBirthdayForDownalods(account.date_of_birth_struct);
        }

        if (account.social_network && Object.keys(account.social_network).length > 0) {
          let linksString = '';
          for (const socialNetworkType in account.social_network) {
            linksString = `${
              linksString + this.showUserSocialLink(socialNetworkType, account.social_network[socialNetworkType])
            }  `;
          }
          socialLinks = linksString.trim();
        }
        accountsToExcel.push([username, profile, name, birthday, socialLinks]);
      }
      return result;
    },
    showUserBirthday(struct) {
      const { year, month, day } = struct;
      const date = new Date(year, month - 1, day);
      return `${moment(date).format('D MMMM YYYY')}`;
    },
    showUserBirthdayForDownalods(struct) {
      const { year, month, day } = struct;
      const date = new Date(year, month - 1, day);
      return `${moment(date).format('DD.MM.Y')}`;
    },
    showUserAge(struct) {
      const { year, month, day } = struct;
      const date = new Date(year, month - 1, day);
      const currentDate = new Date();
      const diff = moment(currentDate).diff(date, 'years', true);
      return diff.toFixed(0);
    },
    showUserSocialLink(type, socialData) {
      const { id } = socialData;
      if (type === 'vk') {
        const vkId = `id${id}`;
        return new URL(vkId, 'https://vk.com/');
      }
      if (type === 'fb') return new URL(id, 'https://facebook.com/profile.php?id=');
      return '';
    },
    getFullName(name, surname) {
      if (name && surname) {
        return `${name} ${surname}`;
      }
      if (!name && !surname) {
        return '';
      }
      if (!name || !surname) {
        return name ? `${name}` : `${surname}`;
      }
      return '';
    },
    getProfileNameByID(id) {
      if (this.portalProfilesList) {
        const result = this.portalProfilesList.find((item) => item.id === id);
        if (result) {
          return result.name;
        }
        return '';
      }
      return '';
    },
    getFormatMac: helpers.getFormatMac,
    onDayClick(date) {
      this.selectedDateOnCalendar = date;
      this.filters.day = date.getDate();
      // this.filters.month = date.getMonth() + 1;
    },
    getUsersBirthdays() {
      // portalBirthdayService.getUserAccounts(this);
      portalBirthdayService.getUserAccounts(this, true);
    },
    setFilterProfile(profile) {
      this.filters.profiles = profile;
    },
    changePaneCalendar(year, month, pane) {
      this.selectedDateOnCalendar = new Date(year, month);
      this.filters.day = '';
      this.filters.month = month + 1;
      this.yearOnCalendar = year;
      // console.info(year, month);
    },
    /**
     * Используется в сервисе
     */
    setEvents(users) {
      // const users = this.$store.state.portalUserAccountsListByBirthday || [];
      const allEvents = users.map((user) => {
        const { month, day } = user.date_of_birth_struct;
        let year;
        if (this.yearOnCalendar === null) {
          year = new Date().getFullYear();
        } else {
          year = this.yearOnCalendar;
        }
        return {
          id: moment(new Date(year, month - 1, day)).format('MM/DD/YYYY')
        };
      });
      const evt = this.getCountIds(allEvents);
      return evt;
    },
    getCountIds(target) {
      const result = {};

      target.forEach((item) => (result[item.id] ? result[item.id]++ : (result[item.id] = 1)));

      return Object.keys(result).map((item) => ({
        id: item,
        content: result[item]
      }));
    }
  },

  computed: {
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    selectedProfileInMultiselectForRequest() {
      const result = [];
      if (this.selectedProfileInMultiselect.length === 0) {
        return result;
      }
      for (const profile of this.selectedProfileInMultiselect) {
        result.push(profile.id);
      }
      return result;
    },
    isFiltersActive() {
      return (typeof this.filters.location === 'object' && Object.keys(this.filters.location).length) ||
        (Array.isArray(this.selectedProfileInMultiselect) && this.selectedProfileInMultiselect.length !== 0) ||
        this.selectedDateOnCalendar.getMonth() !== new Date().getMonth()
    },
    portalProfilesListForFilterMultiselect() {
      const list =
        this.$store.state.portalProfilesList && this.$store.state.portalProfilesList.length ?
          this.$store.state.portalProfilesList :
          [];
      const listForMultiselect = JSON.parse(JSON.stringify(list));
      if (listForMultiselect.length && listForMultiselect[0].id) {
      } else {
        listForMultiselect.shift();
      }
      listForMultiselect.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1;
        }
        if (a.name.toLowerCase() < b.name.toLowerCase()) {
          return -1;
        }
        return 0;
      });
      return listForMultiselect;
    },
    muteEdit() {
      return this.$store.state.mutePortalUserAccountEdit;
    },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    userAccountsListByBirthday() {
      return this.$store.state.portalUserAccountsListByBirthday;
    },
    // userAccountsListByBirthdayForCalendar() {
    //   return
    // }
    userAccountsList() {
      return this.$store.state.portalUserAccountsList;
    },
    portalProfilesList() {
      const list = this.$store.state.portalProfilesList || [];
      if (list.length && list[0].id) {
        list.unshift({ name: this.$t('portalStats.allProfiles') });
      }
      return list;
    },
    isPortalBirthdaysLoading() {
      return this.$store.state.loadingPortalBirthdays || this.$store.state.loadingPortalProfiles;
    },
    showSelectedDate() {
      const { year, month, day } = this.filters;
      if (day === '') {
        return moment(new Date(year, month, day)).format('MMMM');
      }
      if (month === '') {
        return moment(new Date(year, month - 1, day)).format('DD');
      }
      return moment(new Date(year, month - 1, day)).format('ll');
    }
  },
  // created() {
  //   // ставим локаль календаря
  //   helpers.setBirthdaysCalendarLocale();
  //   this.$store.commit('portalProfilesListClean');
  //   if (this.userAccountsList.length < 1) portalUserAccountsService.getUserAccounts(this);
  //   if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
  //   // this.getUsersBirthdays();
  // },

  created() {
    // ставим локаль календаря
    helpers.setBirthdaysCalendarLocale();
    this.$store.commit('portalProfilesListClean');

        let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }
      if (locationForFiltering === 'All locations') {
        this.filters.location = {};
        this.filters.with_childs = true;
      } else {
        this.filters.location = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
        this.filters.with_childs = withChildsForFiltering
      }
    }


    // if (this.userAccountsList.length < 1) portalUserAccountsService.getUserAccounts(this);
    if (this.portalProfilesList.length < 1) portalProfilesService.getPortalProfiles(this);
    // this.getUsersBirthdays();
  },
  mounted() {
    this.getUsersBirthdays();
  }
};
</script>

<style lang="scss" scoped>
.mx-datepicker {
  width: 100% !important;
}
.mx-datepicker-body {
  display: flex;
  justify-content: center;
}

.img-thumbnail {
  display: block;
  max-width: 200px;
  border: 0;
}

.video-thumbnail {
  max-width: 100%;
  height: 100%;
}

.ads-schedule {
  margin: 0;
  padding: 0;

  list-style: none;
}

.ads-schedule__status--active {
  color: #4dbd74;
}

.ads-schedule__status--planned {
  color: #f8cb00;
}

.ads-schedule__status--done {
  color: #94a0b2;
}

.ads-schedule .card-header {
  flex-wrap: wrap;

  font-size: 0.875rem;
}

.ads-schedule .card-block {
  padding: 0.75rem 1.25rem;
}

.modal {
  // 0.4 - scaling const for iphone
  // 0.3 - scaling const for macbook

  .half-width {
    width: 46%;
  }
  .upload {
    line-height: 1.25;
  }
  .text-muted {
    text-overflow: ellipsis;
  }

  .interface-block {
    border-radius: 10px;
    margin: 0 1rem;
    padding: 1.5rem;
    background: rgba(236, 236, 236, 0.45);
  }
}

.custom-control {
  display: block;
}
</style>

<style lang="scss">
    .multiselect-in-PortalBirthdays--filters {
  min-height: 23px !important;
}

.multiselect-in-PortalBirthdays--filters .multi-select__control {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  max-height: 300px !important;
  min-height: 23px !important;
  align-items: center;
}

.multiselect-in-PortalBirthdays--filters .multi-select__input {
  min-height: 23px !important;
  margin-bottom: 0px !important;
}

.multiselect-in-PortalBirthdays--filters .arrow {
  min-height: 23px !important;
}

.multiselect-in-PortalBirthdays--filters .multi-select__tag {
  margin-bottom: 0px;
  height: 22px !important;
}

    .input-group-for-location-select-in-PortalBirthdays--filters {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
    max-width: 100%;
    z-index: 9997 !important;
  }

  .select-location-in-PortalBirthdays--filters .select__list-container {
    width: 400px;
  }


.datepicker {
  display: flex;
  justify-content: center;
}
.datepicker-wrapper {
  z-index: 0;
  width: 251px;
}
.datepicker-inner {
  width: 251px;
}
.datepicker-popup {
  z-index: 0;
  position: relative !important;
  border: 0 solid #fff;
  border-radius: 0;
  background: #fff;
  margin-top: 0px;
  box-shadow: none;
}
.datepicker-ctrl p,
.datepicker-ctrl span,
.datepicker-body span {
  display: inline-block;
  width: 33px;
  line-height: 33px;
  height: 33px;
  border-radius: 0;
}
.day-cell div {
  position: relative;
}
.day-cell div div {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.day-icon {
  /* position: absolute; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  background-color: #ffa538;
  border-radius: 50%;
  font-weight: 800;
  font-size: 10px;
  width: 12px;
  line-height: 12px;
  height: 12px;
}
.day-icon small {
  font-weight: bold;
}
.datepicker-dateRange-item-active:hover,
.datepicker-dateRange-item-active {
  background: #20a8d8 !important;
  color: white !important;
}

.loader-backdrop-portalads:after {
  position: absolute;
  top: 30px;
}
</style>

<style lang="scss" scoped>
table tr {
  cursor: default;
}
.submit {
  & button {
    // position: relative;
    margin-top: 28px;
    height: 40px;
    width: 100%;
  }
}
.loader-all-page-loading:after {
  position: absolute;
  top: 40vh;
}
.star {
  font-size: 1em !important;
  color: #ffa602 !important;
}

.days-of-week .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.time-of-day .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}
.platform .form-check-input {
  position: static;
  margin-top: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.schedule-caption-in-ad {
  clear: both;
}

.cool-modern-selector input {
  display: none !important;
}

.cool-modern-selector input[type='checkbox'] + label {
  display: inline-block;
  padding-right: 20px;
  border-radius: 6px;
  background: #dddddd;
  color: #939393;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-top: 3px;
  margin-bottom: 3px;
  user-select: none;
}

.cool-modern-selector input[type='checkbox']:checked + label {
  background: #4dbd74;
  color: #ffffff;
}
.modal-info-in-ads {
  min-width: 650px;
}
.views-statistic {
  font-size: 0.9em;
}

.fix-overflowed-table {
  word-break: break-all;
  word-wrap: break-word;
}
</style>

<!-- NOTE: icons from http://www.iconarchive.com/show/free-multimedia-icons-by-designbolts/Photo-icon.html -->
